import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AdminAuth } from '../Element/Admin/AdminAuth';
import { DataAdmin } from './DataAdmin';

const useStyles = makeStyles(theme => ({
    //For this
    root: {
        justifyContent: 'center',
        alignContent: 'center',
        borderColor: 'transparent',
        background: '#393D4D'
    },
    title: {
        paddingTop: 5,
    },
}));

export function MainAdmin() {
    //Стили
    const classes = useStyles();

    //Токен авторизации
    const [token, setToken] = useState(null);
        //useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJVc2VyIiwibmJmIjoxNjEwODgwNTkyLCJleHAiOjE2MTk1MjA1OTIsImlzcyI6Ik5vRmF0SSIsImF1ZCI6Ik5vRmF0QSJ9.oGWY4f_ndukeE8Uibhn1jR1jIOB7Z4qZTNVruexZzFY');

    return (
        <div className={classes.root}>
            {
                token === null ?
                    <AdminAuth
                        onTokenChange={setToken}
                    /> :
                    <DataAdmin token={ token } />
            }
        </div>
    );
}