import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { MainColor } from '../../../infrastructure/VisualPro';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        alignItems: "center",
        verticalAlign: 'middle',
        paddingLeft: 1.0,
        paddingRight: 1.0,
        color: MainColor(),
    },
    info: {
        alignItems: "center",
        verticalAlign: 'middle',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 1.0,
    },
    iconSend: {
        verticalAlign: 'middle',
        color: MainColor(),
        padding: 1.0,
    },
}));

export function ProfileCounters(props) {
    //Стили
    const classes = useStyles();
    //Профиль
    const profile = props.profile;

    if (profile == null) return (<div />);
    return (
        <div key={profile.Id + 'ProfileCountersBlock'} >
            <Grid container spacing={2} alignItems="center" justify="flex-end">
                <Grid item>
                    <Tooltip title="Количество челленджей">
                        <div className={classes.info}>
                            <EmojiEventsIcon className={classes.icon} fontSize="small" />
                             <div className={classes.info}>
                                {profile.Challenges != null ? profile.Challenges.length : 0}
                            </div>
                        </div>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {
                        //Скрыт вес или показан
                        profile.HideWeight === true 
                            ? <Tooltip title={'Пользователь скрыл свой вес'}>
                                <VisibilityOffIcon className={classes.icon} fontSize="small" />                        
                            </Tooltip >
                            : <Tooltip title={'Вес пользователя виден'}>
                                <VisibilityIcon className={classes.icon} fontSize="small" />
                            </Tooltip >
                    }
                </Grid >
                <Grid item>
                    {
                        //Тип ОС
                        profile.Platform != null
                            ? profile.Platform === 1
                                ? <Tooltip title={'Устройтсво на Android'}>
                                    <div className={classes.info}>
                                        <PhoneAndroidIcon className={classes.icon} fontSize="small" />
                                        <div className={classes.info}>
                                            Android
                                        </div>
                                    </div>
                                </Tooltip >
                                : <Tooltip title={'Устройтсво на iOS'}>
                                    <div className={classes.info}>
                                        <PhoneIphoneIcon className={classes.icon} fontSize="small" />
                                        <div className={classes.info}>
                                            iOS
                                        </div>
                                    </div>
                                </Tooltip >
                            : <Tooltip title={'Устройтсво неизвестно'}>
                                <StayCurrentPortraitIcon className={classes.icon} fontSize="small" />                                
                            </Tooltip >
                    }
                </Grid >                
                {
                    props.clickable &&
                    //Показать / скрыть челленджи
                    <Grid item>
                        <Tooltip title={(profile.IsSelected ? 'Скрыть' : 'Показать') + ' челленджи'}>
                            <IconButton size="small" onClick={() => { props.onClick(profile.Id); }}>
                                {
                                    profile.IsSelected
                                        ? <ExpandLessIcon className={classes.iconSend} fontSize="large" />
                                        : <ExpandMoreIcon className={classes.iconSend} fontSize="large" />
                                }
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        </div>
    );
}