//Состояние и данные запроса
export function ChangeState (state, action) {
    switch (action.type) {
        case "init":
            return {
                ...state,
                isLoading: true,
                isError: false,
                isOk: false,
                data: null
            };
        case "done":
            return {
                ...state,
                isLoading: false,
                isError: false,
                isOk: true,
                data: action.data
            };
        case "error":
            return {
                ...state,
                isLoading: false,
                isError: true,
                isOk: false,
                data: action.data
            };
        default:
            return null;
    }
}

export function GetInitialState() {
    return {
        isLoading: false,
        isError: false,
        isOk: false,
        data: null
    }
}

export function GetType(type) {
    switch (type) {
        case 'GET':
        case 'POST':
        case 'PUT':
        case 'DELETE':
            return type;
        default: {
            console.log('Unknown request type: ' + type);
            return 'GET';
        }
    }
}