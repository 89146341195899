import React from 'react';
import Grid from '@material-ui/core/Grid';

export function CenterItem(props) {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            {props.children}
        </Grid>
    );
}