import React  from 'react';
import { Bolding } from '../../../infrastructure/Helper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ProfileCounters } from './ProfileCounters';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import { ChallengeCard } from './ChallengeCard';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';
import { dateTimeToString } from '../../../infrastructure/Helper';

//По клику открываем в отдельной вкладке
const imgClick = link => {
    window.open(link, '');
}

export function ProfileCard(props) {
    //Профиль
    const profile = props.profile;

    //Можем кликнуть или нет
    const clickable = profile.Challenges != null && profile.Challenges.length > 0 && props.onClick != null;

    if (profile == null) return (<div />);
    let name = (profile.Surname != null ? profile.Surname + ' ' + profile.Name : profile.Name);
    return (
        <div key={profile.Id + 'Block'}>
            <Card className={props.classes.profileCard} >
                <CardContent>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={isMobile ? 12 : 6}>
                            { /* Первая половина */}
                            <Grid container spacing={2} justify="flex-start" alignItems="center">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center" justify="center" direction="column">
                                        { /* Аватарка */}
                                        <Grid item>
                                            {
                                                profile.Photo != null
                                                    ? <Avatar aria-label="recipe" className={props.classes.avatar}
                                                        onClick={() => imgClick(profile.Photo)}
                                                        src={profile.Photo}
                                                    />
                                                    : <Avatar aria-label="recipe" className={props.classes.avatar}>
                                                        <AccountCircleIcon />
                                                    </Avatar>
                                            }
                                        </Grid>
                                        { /* ID */}
                                        <Grid item>
                                            {'ID = ' + profile.Id}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                { /* Основная информация */}
                                <Grid item>
                                    <Grid container spacing={0} alignItems="flex-start" justify="center" direction="column">
                                        { /* Имя, фамилия */}
                                        <Grid item>
                                            {Bolding(name)}
                                        </Grid>
                                        { /* Почта или статус упрощенного аккаунта */}
                                        <Grid item>
                                            {
                                                profile.IsSimple === true
                                                    ? 'Упрощенный аккаунт'
                                                    : profile.Mail
                                            }
                                        </Grid>
                                        { /* Дата создания */}
                                        <Grid item>
                                            {
                                                'Создан: ' + 
                                                (profile.DateReg != null
                                                    ? dateTimeToString(profile.DateReg)
                                                    : 'неизвестно')
                                            }
                                        </Grid>
                                        { /* Если удалён */}
                                        {
                                            profile.Deleted === true &&
                                                <Grid item>
                                                    <Typography variant="body2" color="error">
                                                        Удален
                                                    </Typography>
                                                </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        { /* Вторая половина */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Grid container spacing={2} justify="flex-end" alignItems="center">
                                {/* Счётчики */}
                                <Grid item>
                                    {<ProfileCounters profile={profile} clickable={clickable} onClick={props.onClick} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                {
                    //Список челленджей
                    profile.IsSelected === true &&
                    <CardContent>
                        <List component="nav" className={props.classes.list}>
                            {
                                profile.Challenges.map((challengeId) =>
                                    <li key={profile.Id + 'challengeOfProfile' + challengeId}>
                                        <ChallengeCard
                                            challenge={props.challenges.find(i => i.Id === challengeId)}
                                            classes={props.classes}
                                            onClick={props.onChallengeClick}
                                        />
                                    </li>
                                )
                            }
                        </List>
                    </CardContent>
                }
            </Card>
        </div>
    );
}