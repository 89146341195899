import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Bolding, Coursive } from '../../infrastructure/Helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MainColor, SecColor } from '../../infrastructure/VisualPro';
import { GetData } from '../../api/ApiHelper';
import { useSendRequest } from '../../api/useSendRequest';
import { CenterItem } from '../Element/CenterItem';
import { ProfileCard } from '../Element/Admin/ProfileCard';
import { ChallengeCard } from '../Element/Admin/ChallengeCard';
import List from '@material-ui/core/List';

const useStyles = makeStyles(theme => ({
    //For this
    root: {
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: theme.spacing(1),
        textAlign: 'center',
        marginLeft: 20,
        marginRight: 20,
    },
    info: {
        alignItems: "center",
        verticalAlign: 'middle',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 1.0,
    },
    title: {
        paddingTop: 25,
    },
    data: {
        paddingTop: 25,
    },
    cycle: {
        colorPrimary: MainColor(),
    },
    list: {
        width: '100%',
    },
    actionButton: {
        marginTop: 15,
        marginLeft: 15,
        marginRight: 15,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: MainColor(),
        borderRadius: 15,
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
        alignContent: 'center',
    },
    actionString: {
        display: 'flex',
        flexWrap: 'nowrap',
        marginRight: 15,
    },
    //For Profile and Challenge cards
    profileCard: {
        margin: 5,
        padding: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: MainColor(),
        borderRadius: 15,
        backgroundColor: SecColor(),
    },
    iconButton: {
        colorPrimary: MainColor(),
    },
    avatar: {
        backgroundColor: MainColor(),
    },
}));

export function DataAdmin(props) {
    //Стили
    const classes = useStyles();

    //Состояние запуска
    const [inited, setInited] = useState(false);

    //Обновление
    const onRefreshClick = event => {
        //Попробуем ещё раз получить данные
        setHasData(false);
        setInited(false);
    }

    //Состояние загрузки
    const [loading, setLoading] = useState(false);

    //Токен авторизации
    const [token] = useState(props.token);

    //Есть данные или нет
    const [hasData, setHasData] = useState(false);

    //Показываем профили или челленджи
    const [showProfiles, setShowProfiles] = useState(true);

    //Ошибка, если есть
    const [errorText, setErrorText] = useState(null);

    //Профили
    const [profiles, setProfiles] = useState(null);

    //Челленджи
    const [challenges, setChallenges] = useState(null);

    //Нужно отправлять запрос на получение данных
    const [needData, setNeedData] = useState(null);

    //Результаты запроса на получение данных
    const [getData] = useSendRequest('GET', GetData(), null, needData, token);

    //Срабатывает, когда меняется ответ запроса на получение профилей
    useEffect(() => {
        //Пометим, что запрос принят к исполнению
        if (needData) setNeedData(false);
        //Если есть ответ, разберём его
        if (getData) {
            if (getData.isOk === true) {
                //Ответ, разберём его
                console.log("Data response");
                setProfiles(getData.data.Profiles.reverse());
                setChallenges(getData.data.Challenges.reverse());
                setLoading(false);
                setHasData(true);
            }
            else if (getData.isError) {
                setErrorText(getData.data);
                setLoading(false);
            }
        }
    }, [getData]);

    //Если данных нет, пробуем получить их
    if (inited !== true) {
        setLoading(true);
        setInited(true);
        //Отправляем запрос
        setNeedData(true);
    }

    //Обработка нажатия на профиль
    const onProfileClick = id => {
        let array = [...profiles];
        let profile = array.find(i => i.Id === id);
        if (profile === undefined) {
            console.info('unknown profile id = ' + id);
            return;
        }
        profile.IsSelected == null ? profile.IsSelected = true : profile.IsSelected = !profile.IsSelected;
        setProfiles(array);
    }

    //Обработка нажатия на челлендж
    const onChallengeClick = id => {
        let array = [...challenges];
        let challenge = array.find(i => i.Id === id);
        if (challenge === undefined) {
            console.info('unknown challenge id = ' + id);
            return;
        }
        challenge.IsSelected == null ? challenge.IsSelected = true : challenge.IsSelected = !challenge.IsSelected;
        setChallenges(array);
    }

    return (
        <div className={classes.root}>
            {/* Заголовок */}
            <Typography variant="h5" color="primary" className={classes.title}>
                {Bolding('NoFat')}
            </Typography>
            {/* Кнопка обновления данных */}
            {
                (inited === true && loading === false)
                    ? <CenterItem>
                        <Tooltip title="Заново загрузить все данные">
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.actionButton}
                                onClick={onRefreshClick}>
                                Обновить данные
                            </Button>
                        </Tooltip>
                    </CenterItem>
                    : <div />
            }
            {/* Данные */}
            <div className={classes.data}>
                {/* Индикатор загрузки */}
                {
                    loading === true &&
                        <CircularProgress color="primary" className={classes.cycle} />
                }
                {/* Ошибка */}
                {
                    errorText !== null
                        ? <Typography variant="subtitle1" color="error" className={classes.subtitle}>
                            {Coursive(errorText)}
                        </Typography>
                        : <div />
                }
                {/* Статистика */}
                {
                    hasData === true &&
                        <div>
                            <Typography variant="subtitle1" color="primary" className={classes.subtitle}>
                                {'Общее количество пользователей: ' + profiles.length}
                            </Typography>
                            <Typography variant="subtitle1" color="primary" className={classes.subtitle}>
                                {'Общее количество челленджей: ' + challenges.length}
                            </Typography>
                        </div>
                }
                {/* Пользователи или челленджи */}
                {
                    hasData === true &&
                    <CenterItem>
                        <div className={classes.actionString}>
                            <Tooltip title="Показать список пользователей">
                                <Button
                                    variant={showProfiles ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => { setShowProfiles(true); }}
                                    size="large"
                                    className={classes.actionButton}>
                                    Пользователи
                                </Button>
                            </Tooltip>
                                <Tooltip title="Показать список челленджей">
                                <Button
                                    variant={showProfiles ? "outlined" : "contained"}
                                    color="primary"
                                    onClick={() => { setShowProfiles(false); }}
                                    size="large"
                                    className={classes.actionButton}>
                                    Челленджи
                                </Button>
                            </Tooltip>
                        </div>
                    </CenterItem>
                }
                {/* Сами данные */}
                {
                    hasData === true ?
                        showProfiles
                            ? < List component="nav" className={classes.list}>
                                {
                                    profiles.map((profile) =>
                                        <li key={'p' + profile.Id}>
                                            <ProfileCard
                                                profile={profile}
                                                classes={classes}
                                                onClick={onProfileClick}
                                                challenges={challenges}
                                                onChallengeClick={onChallengeClick}
                                            />
                                        </li>
                                    )
                                }
                            </List>
                            : <List component="nav" className={classes.list}>
                                {
                                    challenges.map((challenge) =>
                                        <li key={'c' + challenge.Id}>
                                            <ChallengeCard
                                                challenge={challenge}
                                                classes={classes}
                                                onClick={onChallengeClick}
                                            />
                                        </li>
                                    )
                                }
                            </List>
                        :<div/>
                }
            </div>
        </div>
    );
}