import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { MainColor } from '../../../infrastructure/VisualPro';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        alignItems: "center",
        verticalAlign: 'middle',
        paddingLeft: 1.0,
        paddingRight: 1.0,
        color: MainColor(),
    },
    info: {
        alignItems: "center",
        verticalAlign: 'middle',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 1.0,
    },
    iconSend: {
        verticalAlign: 'middle',
        color: MainColor(),
    },
}));

export function ChallengeCounters(props) {
    //Стили
    const classes = useStyles();
    //Челлендж
    const challenge = props.challenge;

    if (challenge == null) return (<div />);
    return (
        <div key={challenge.Id + 'ChallengeCountersBlock'} >
            <Grid container spacing={2} alignItems="center" justify="flex-end">
                <Grid item>
                    <Tooltip title="Количество участников">
                        <div className={classes.info}>
                            <AssignmentIndIcon className={classes.icon} fontSize="small" />
                             <div className={classes.info}>
                                {challenge.Competitors != null ? challenge.Competitors.length : 0}
                            </div>
                        </div>
                    </Tooltip>
                </Grid>
                {
                    props.clickable &&
                     //Показать / скрыть участников
                    <Grid item>
                        <Tooltip title={(challenge.IsSelected ? 'Скрыть' : 'Показать') + ' участников'}>
                            <IconButton size="small" onClick={() => { props.onClick(challenge.Id); }}>
                                {
                                    challenge.IsSelected
                                        ? <ExpandLessIcon className={classes.iconSend} fontSize="large" />
                                        : <ExpandMoreIcon className={classes.iconSend} fontSize="large" />
                                }
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        </div>
    );
}