import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Bolding, Coursive } from '../../../infrastructure/Helper';
import { MainColor, SecColor } from '../../../infrastructure/VisualPro';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { useSendRequest } from '../../../api/useSendRequest';
import { GetAuth } from '../../../api/ApiHelper';

const useStyles = makeStyles(theme => ({
    //For this
    root: {
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: theme.spacing(1),
        textAlign: 'center',
        marginLeft: 20,
        marginRight: 20,
    },
    title: {
        paddingTop: 25,
    },
    searchInput: {
        margin: 10,
        paddingLeft: 10,
        paddingRight: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: MainColor(),
        borderRadius: 15,
        backgroundColor: SecColor(),
        width: '100%',
    },
    searchButton: {
        marginLeft: 15,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: MainColor(),
        borderRadius: 15,
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
        alignContent: 'center',
    },
}));

export function AdminAuth(props) {
    //Стили
    const classes = useStyles();

    //Введенный текст
    const [text, setText] = useState(null);

    //Ошибка, если есть
    const [errorText, setErrorText] = useState(null);

    //Нужно отправлять запрос на авторизацию или нет
    const [needTryAuth, setNeedTryAuth] = useState(null);

    //Результаты запроса на авторизацию
    const [tryAuth] = useSendRequest('POST', GetAuth(), text, needTryAuth, null);

    //Срабатывает, когда меняется ответ запроса на авторизацию
    useEffect(() => {
        //Пометим, что запрос принят к исполнению
        if (needTryAuth) setNeedTryAuth(false);
        //Если есть ответ, разберём его
        if (tryAuth) {
            if (tryAuth.isOk === true) {
                //Ответ, разберём его
                console.log("Auth response");
                props.onTokenChange(tryAuth.data.Token);
            }
            else if (tryAuth.isError) {
                setErrorText(tryAuth.data);
            }
        }
    }, [tryAuth]);

    //Обработка ввода текста
    const onTextChange = text => {
        setText(text);
    }

    //Пробуем авторизоваться
    const onAuthClick = event => {
        if (text === null || text === '') {
            setErrorText('Не указан пароль');
            return;
        }
        setErrorText(null);
        setNeedTryAuth(true);
    };

    return (
        <div className={classes.root}>
            {/* Заголовок */}
            <Typography variant="h5" color="primary" className={classes.title}>
                {Bolding('Авторизация')}
            </Typography>
            {/* Блок ввода пароля */}
            <Grid
                container
                spacing={2}
                justify="center" 
                alignItems="center" >
                <Grid item>
                    <InputBase
                        type="password"
                        onChange={(event) => { onTextChange(event.target.value); }}
                        className={classes.searchInput} />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { onAuthClick(); }}
                        size="large"
                        className={classes.searchButton}
                    >
                        Продолжить
                    </Button>
                </Grid>
            </Grid>
            {/* Ошибка */}
            {
                errorText !== null
                    ? <Typography variant="subtitle1" color="error" className={classes.subtitle}>
                        {Coursive(errorText)}
                    </Typography>
                    : <div />
            }
        </div>
    );
}