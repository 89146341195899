import { useReducer, useEffect } from 'react';
import { ChangeState, GetInitialState, GetType } from './RequestState';

//type - тип запроса (GET/POST/...)
//url для отправки запроса (уже со всеми параметрами)
//body - тело запроса
//Для отправки запроса needSend должно быть true
export function useSendRequest(type, url, body, needSend, token) {
    //Текущее состояние запроса со всеми данными
    const [state, setState] = useReducer(ChangeState, GetInitialState());

    useEffect(() => {
        if (needSend === true) {
            send();
        }

        async function send() {
            //Проверим тип запроса
            let reqType = GetType(type);
            //Соберём тело, если нужно
            let reqBody = body && JSON.stringify(body);
            //Залогируем
            console.log('Start ' + reqType + ' ' + url + ' body: ' + reqBody);
            //Запускаем процесс
            setState({ type: 'init' });
            try {
                //Отправим запрос
                const response = await fetch(
                    url,
                    {
                        method: reqType,
                        headers: { 'Content-Type': 'application/json', 'Authorization': token == null ? '' : 'Bearer ' + token },
                        body: reqBody,
                    }
                );
                //Если всё хорошо, возвращаем данные
                if (response.ok && response.status === 200) {
                    let outputData;
                    try {
                        outputData = await response.json();
                    }
                    catch (error) { }
                    setState({ type: 'done', data: outputData });
                }
                else {
                    var message = '';
                    //Что-то не так, пришла ошибка
                    if (response.status === 400 || response.status === 500) {
                        //Если пришла детализованная ошибка с модуля API, показываем описание
                        var json = (await response.json());
                        var errorText = json['Message'];
                        message = errorText == null ? response.statusText : errorText;
                    }
                    else if (response.status === 204) {
                        //Значит, неверный пароль
                        message = 'Неверный пароль';
                    }
                    else {
                        message = 'Ошибка ' + response.status + ': ' + response.statusText;
                    }
                    console.log(message);
                    setState({ type: 'error', data: message });
                }
            }
            catch (error) {
                    console.log(error);
                    setState({
                        type: 'error',
                        data: 'Ошибка при выполнении запроса (возможно, нет связи с сервером)',
                    });
                };
            console.log('End ' + reqType + ' ' + url);
        }
    }, [type, url, body, needSend]);

    return [state];
}