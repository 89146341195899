import React from 'react';

//Выделяем текст жирным
export function Bolding(str) {
    return (<b>{str}</b>);
}

//Выделяем текст жирным
export function Coursive(str) {
    return (<i>{str}</i>);
}

//Дополняем число и год до двузначного числа
function PadValue(value) {
    return (value.toString().padStart(2, '0'))
}

//Преобразование даты из DateTime
export function dateTimeToString(dateTime) {
    // Вернём в виде dd.mm.yyyy
    var date = new Date(dateTime);
    return PadValue(date.getDate()) + '.' +
        PadValue(date.getMonth() + 1) + '.' +
        date.getFullYear();
}