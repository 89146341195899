import React, { Component } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/styles';
import { Route, Switch } from 'react-router';
import { MainInfo } from './components/Main/MainInfo';
import { MainColor, SecColor, TextMainColor, TextSecColor } from './infrastructure/VisualPro';
import { isMobile } from 'react-device-detect';
import { MainAdmin } from './components/Admin/MainAdmin';

const theme = createMuiTheme({
    spacing: isMobile ? 2 : 8,
    palette: {
        background: {
            default: "#393D4D"
        },
        primary: {
            main: MainColor(),
        },
        secondary: {
            main: SecColor(),
        },
        text: {
            primary: TextMainColor(),
            secondary: TextSecColor(),
        },
        typography: {
            fontFamily: [
                'Open Sans',
            ].join(','),
        }
    },
});

export default class App extends Component {
    render() {
        document.title = "NoFat";
      return (
          <ThemeProvider theme={theme}>
                <CssBaseline />
                <Switch>
                    <Route exact path='/admin' component={MainAdmin} />
                    <Route component={MainInfo} />
                </Switch>
          </ThemeProvider>
        );
    }
}
