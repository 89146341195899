import React  from 'react';
import { Bolding } from '../../../infrastructure/Helper';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { ChallengeCounters } from './ChallengeCounters';
import { dateTimeToString } from '../../../infrastructure/Helper';
import List from '@material-ui/core/List';
import { CompetitorCard } from './CompetitorCard';
import { isMobile } from 'react-device-detect';

export function ChallengeCard(props) {
    //Челлендж
    const challenge = props.challenge;

    //Можно кликнуть или нет
    const clickable = challenge.Competitors != null && challenge.Competitors.length > 0 && props.onClick != null

    if (challenge == null) return (<div />);
    let name = dateTimeToString(challenge.DateBegin) + ' - ' + dateTimeToString(challenge.DateEnd);
    return (
        <div key={challenge.Id + 'Block'}>
            <Card className={props.classes.profileCard} >
                <CardContent>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={isMobile ? 12 : 6}>
                            { /* Первая половина */}
                            <Grid container spacing={2} justify="flex-start" alignItems="center">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center" justify="center" direction="column">
                                        { /* ID */}
                                        <Grid item>
                                            {'ID = ' + challenge.Id}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                { /* Основная информация */}
                                <Grid item>
                                    <Grid container spacing={0} alignItems="flex-start" justify="center" direction="column">
                                        { /* Даты проведения */}
                                        <Grid item>
                                            {Bolding(name)}
                                        </Grid>
                                        { /* Краткая ссылка */}
                                        <Grid item>
                                            {challenge.Link}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        { /* Вторая половина */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Grid container spacing={2} justify="flex-end" alignItems="center">
                                {/* Счётчики */}
                                <Grid item>
                                    {<ChallengeCounters challenge={challenge} clickable={clickable} onClick={props.onClick} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                {
                    //Список участников
                    challenge.IsSelected === true &&
                    <CardContent>
                        <List component="nav" className={props.classes.list}>
                            {
                                challenge.Competitors.map((competitor) =>
                                    <li key={challenge.Id + 'competitorOfChallenge' + competitor.Id}>
                                        <CompetitorCard
                                            competitor={competitor}
                                            classes={props.classes}
                                        />
                                    </li>
                                )
                            }
                        </List>
                    </CardContent>
                }
            </Card>
        </div>
    );
}