//Возвращаем основной цвет
export function MainColor() {
    return ("#38D9A9");
}

//Возвращаем вторичный цвет - белый цвет
export function SecColor() {
    return ("#FFFFFF");
}

//Основной цвет текста - чёрный
export function TextMainColor() {
    return ("#000000");
}

//Вторичный цвет текста - белый
export function TextSecColor() {
    return SecColor();
}