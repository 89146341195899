function GetBaseUrl() {
    return 'https://nofat.io';
}


//Возвращаем данные для запроса по названию метода и входным данным
function GetUrl(name) {
    return GetBaseUrl() + name;
}

//Возвращаем данные для запроса по названию метода и входным данным
/*function GetUrlParam(name, param) {
    return GetBaseUrl() + name + '/' + param;
}*/

function UrlAuth() { return "/api/admin/login"; }

function UrlProfiles() { return "/api/admin/profile"; }

function UrlChallenges() { return "/api/admin/challenge"; }

function UrlData() { return "/api/admin/data"; }

//Авторизация
export function GetAuth() { return GetUrl(UrlAuth()); }

//Получение профилей
export function GetProfiles() { return GetUrl(UrlProfiles()); }

//Получение челленджей
export function GetChallenges() { return GetUrl(UrlChallenges()); }

//Получение данных - профилей и челленджей
export function GetData() { return GetUrl(UrlData()); }

//Вспомогательные функции

//Получение полного URL файла для использование извне
export function GetFullFileUrl(file) {
    return GetBaseUrl() + file;
}