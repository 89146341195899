import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CenterItem } from '../Element/CenterItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isMobile } from 'react-device-detect';

//По клику открываем в отдельной вкладке
const imgClick = link => {
    window.location.href = link;
}

const useStyles = makeStyles(theme => ({
    //For this
    root: {
        justifyContent: 'center',
        alignContent: 'center',
        borderColor: 'transparent',
        background: '#393D4D'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    media: {
        height: isMobile ? 150 : 200,
        width: isMobile ? 150 : 200,
        marginTop: theme.spacing(13),
        marginBottom: theme.spacing(8),
    },
    text: {
        justifyContent: 'center',
        alignContent: 'center',
        
    },
    appStore: {
        height: 54,
        width: 162,
    },
    googlePlay: {
        height: 80,
        width: 207,
    },
}));

export function MainInfo() {
    //Стили
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card className={classes.root} variant="outlined">
                <CenterItem>
                    <CardMedia
                        className={classes.media}
                        image="/static/images/Logo.png"                  
                    />
                </CenterItem>
                <CenterItem>
                    <CardContent className={classes.text}>
                        <Typography variant="h5" component="h2" align="center">
                            <Box fontWeight="fontWeightBold" m={1} color='#A5A5A5'>
                                Добро пожаловать в
                            </Box>
                        </Typography>
                        <Typography variant="h2" component="h2" align="center">
                            <Box fontWeight="fontWeightBold" m={1} color='#FFFFFF'>
                                NoFat
                            </Box>
                        </Typography>
                    </CardContent>
                </CenterItem>
                <CenterItem>
                    <CardContent className={classes.text}>
                        <Typography variant="h6" align="center">
                            <Box m={1} color='#A5A5A5'>
                                Для участия в челлендже установите мобильное приложение
                            </Box>
                        </Typography>
                    </CardContent>
                </CenterItem>
                <CenterItem>
                    <CardMedia
                        className={classes.googlePlay}
                        image="/static/images/GooglePlay.png"
                        onClick={() => imgClick("https://play.google.com/store/apps/details?id=com.comraid.nofat")}
                        />
                    <CardMedia
                        className={classes.appStore}
                        image="/static/images/AppStore.png"
                        onClick={() => imgClick("https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1501473139")}
                    />
                </CenterItem>
            </Card>
        </div>
    );
}