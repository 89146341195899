import React  from 'react';
import { Bolding } from '../../../infrastructure/Helper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { dateTimeToString } from '../../../infrastructure/Helper';
import { isMobile } from 'react-device-detect';

//По клику открываем в отдельной вкладке
const imgClick = link => {
    window.open(link, '');
}

export function CompetitorCard(props) {
    //Профиль
    const competitor = props.competitor;

    if (competitor == null) return (<div />);
    let name = (competitor.Surname != null ? competitor.Surname + ' ' + competitor.Name : competitor.Name);
    return (
        <div key={competitor.Id + 'CompetitorBlock'}>
            <Card className={props.classes.profileCard} >
                <CardContent>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={isMobile ? 12 : 6}>
                            { /* Первая половина */}
                            <Grid container spacing={2} justify="flex-start" alignItems="center">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center" justify="center" direction="column">
                                        { /* Аватарка */}
                                        <Grid item>
                                            {
                                                competitor.Photo != null
                                                    ? <Avatar aria-label="recipe" className={props.classes.avatar}
                                                        onClick={() => imgClick(competitor.Photo)}
                                                        src={competitor.Photo}
                                                    />
                                                    : <Avatar aria-label="recipe" className={props.classes.avatar}>
                                                        <AccountCircleIcon />
                                                    </Avatar>
                                            }
                                        </Grid>
                                        { /* ID */}
                                        <Grid item>
                                            {'ID = ' + competitor.Id}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                { /* Основная информация */}
                                <Grid item>
                                    <Grid container spacing={0} alignItems="flex-start" justify="center" direction="column">
                                        { /* Имя, фамилия */}
                                        <Grid item>
                                            {Bolding(name)}
                                        </Grid>
                                        { /* Статус участника*/}
                                        <Grid item>
                                            {
                                                competitor.Founder === true
                                                    ? 'Создатель'
                                                    : 'Участник с ' + dateTimeToString(competitor.DateJoin)
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        { /* Вторая половина */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Grid container spacing={2} justify="flex-end" alignItems="center">
                                {/* Счётчики */}
                                <Grid item>
                                    <Grid container spacing={0} alignItems="flex-start" justify="center" direction="column">
                                        { /* Цель челленджа */}
                                        <Grid item>
                                            {'Цель: ' + competitor.WeightBegin + 'кг -> ' + competitor.WeightGoal + 'кг'}
                                        </Grid>
                                        { /* Текущий вес */}
                                        <Grid item>
                                            {'Текущий вес: ' + competitor.WeightCurrent + 'кг'}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}